@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  background-color: #fff;
  object-fit: contain;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.title {
  color: #404145;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  display: flex;
  justify-content: space-between;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.infoContainer {
  display: flex;
  & span {
    margin: auto 10px;
  }
}

.reviewSection {
  display: flex;
  margin: auto 10px;
  & svg {
    width: 17px;
    height: 15px;
    flex-shrink: 0;
    margin-top: 2px;
  }
  & span {
    color: #5f5f5f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.rating {
  color: var(--Default-Font, #404145);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.favBtn {
  & svg {
    fill: hsl(-2 0% 70% / 1);
  }
  cursor: pointer;
}
.unfavBtn {
  cursor: pointer;

  & svg {
    fill: var(--marketplaceColor) !important;
  }
  & path {
    stroke: var(--marketplaceColor);
  }
}

.favContainer {
  width: 29.783px;
  height: 29.783px;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  margin: 5px 10px 0 0;
  & > button {
    all: unset;
  }
}

.certifiedBadgeContainer {
  flex-shrink: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 6px 4px;
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: linear-gradient(
    91deg,
    #d4af37 -9.47%,
    #fff3cb 17.14%,
    #f5dd8c 52.84%,
    #fff3cb 78.75%,
    #d4af37 108.79%
  );
  & > span {
    color: rgb(66, 50, 1);
  }
}
.castTag {
  padding: 2px 10px;
  background-color: var(--marketplaceColor);
  color: #fff;
  left: 0;
  position: absolute;
  bottom: 0;
  margin: 6px 4px;
  border-radius: 5px;
}
