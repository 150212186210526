.videoPlayer {
  position: relative;
  width: 641px;
  /* height: 445px; */
  /* height: fit-content; */
  cursor: pointer;
  margin: 133px 0;
}

video {
  /* width: 100%; */
  display: block;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.playing .playButton {
  display: none;
}
