@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    /* background-color: var(--colorWhite); */

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    background-color: rgba(0, 0, 0, 0.4);
    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;
    margin: auto 10px;
    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--colorWhite);
    border-radius: var(--borderRadius);
    border-bottom: none;

    /* min-height: 100vh; */
    /* height: 100%; */

    @media (min-width: 576px) {
      flex-basis: 576px;
      flex-grow: 0;
      min-height: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
      border-bottom: 8px solid var(--marketplaceColor);
    }
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
  @media (max-width: 767px) {
    position: absolute !important;
  }
}
.titledClose {
  padding-top: 20px !important;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
  fill: #4a4a4f !important;
}

.closeLight {
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorWhite);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
.modalTitle {
  position: absolute;
  top: 10px;
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  left: 50%;
  transform: translate(-50%, 0);
}
.titleSeparator {
  position: absolute;
  top: 52px;
  height: 1px;
  background-color: #b5b5b5;
  width: 100%;
  left: 0;
}
