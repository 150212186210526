.toast{
    z-index: 11;
    position: fixed;
    padding: 20px 34px;
    background-color: #818181;
    color: #fff;
    border-radius: 10px;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    animation: toastAnimation 0.5s ease;
}
@keyframes toastAnimation {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}