.horizontalNav {
  display: flex;
  align-items: center;
  background-color: var(--colorWhite);
  border-left: 1px solid var(--colorGrey100);
  border-bottom: 1px solid #e4e5e7;
  padding: 20px 32px 20px 59px;
  overflow-x: scroll;
  gap: 60px;
  @media (max-width: 1023px) {
    padding: 20px 24px;
    /* margin-right: calc((100% - 1400px) / 2); */
  }
}

.tabContainer {
  display: flex;
  gap: 20px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.circleContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  background-color: #fff; /* Default circle color */
}

.circle {
  color: #d9d9d9;
}

.index {
  font-size: 16px;
  & svg {
    fill: none;
    height: 40px;
    width: 40px;
  }
}

.label {
  font-size: 16px;
  color: #d9d9d9;
  white-space: nowrap;
}

.symbol {
  font-size: 20px;
  color: #d9d9d9;
}

.exit {
  font-size: 16px;
  cursor: pointer;
  margin-left: auto; /* This will position "Exit" to the rightmost side */
}

.active {
  color: var(--marketplaceColor); /* Adjust as needed */
  white-space: nowrap;
}

.activeCircle {
  background-color: var(--marketplaceColor);
  & div {
    color: #fff;
  }
}

/* Additional styling for better visibility and aesthetics */
.tabs:hover {
  background-color: #f0f0f0;
  text-decoration: none;
}

.disabled {
  pointer-events: none;
}
